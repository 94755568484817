import DefaultLayout from 'layouts/DefaultLayout';
import Grid from '@mui/material/Grid';
import Link from 'next/link';
import Button from '@mui/material/Button';
import React from 'react';
import Typography from '@mui/material/Typography';

const Error = () => (
  <Grid
    container
    spacing={3}
    justifyContent="center"
    alignItems="center"
  >
    <Grid
      item
      textAlign="center"
    >
      <Typography variant="subtitle1">
        If you&apos;re itching to create something, head back home and start a new project.
      </Typography>
      <Link href="/" passHref>
        <Button
          className="backButton"
          variant="contained"
          size="large"
          sx={{ mt: 3 }}
        >
          Back to Home
        </Button>
      </Link>
    </Grid>
  </Grid>
);

Error.getLayout = (page) => (
  <DefaultLayout>{page}</DefaultLayout>
);
export default Error;
